import {useLayoutEffect, useState} from 'react';
import {collection, getDocs, query, where} from "firebase/firestore";
import {db, stripeFunctions} from "./firebase";
import {httpsCallable} from "firebase/functions";
import {ProductDoc} from "./prices";


export function useCurrentSubscriptions(uid: string | undefined) {
  const [subs, setSubs] = useState<any>();

  useLayoutEffect(() => {
    (async () => {
      if (uid) {
        const q = query(
          collection(db, `customers/${uid}/subscriptions`),
          where('status', 'in', ['trialing', 'active']),
        )
        const querySnapshot = await getDocs(q);
        const subs: any[] = [];
        querySnapshot.forEach((doc) => {
          subs.push({
            id: doc.id,
            data: doc.data()
          });
        });
        setSubs(subs);
      }
    })();
  }, [uid]);

  return subs;
}

export function getCurrentProduct(subscriptions: any[], products: ProductDoc[]|undefined) {
  let productId = subscriptions?.[subscriptions.length-1]?.data?.items?.[0]?.plan?.product;

  if (productId === 'prod_NGgJzaG3PXae5R') { // professional
    productId = 'prod_NgKpp5x3441DgW';
  } else if (productId === 'prod_NGgJiJiaJUPmpo') { // standard
    productId = 'prod_NgKngKrDPLlD4M';
  }

  return products?.find((p) => p.id === productId)
    || products?.find((p) => p.id === 'free_plan');
}

const nextBillingDate = (startDate: Date) => {
  const today = new Date();
  const anchorDay = startDate.getDate();
  const nextBilling = new Date(today.getFullYear(), today.getMonth(), anchorDay);
  if (nextBilling < today || nextBilling.getDate() !== anchorDay) {
    nextBilling.setMonth(nextBilling.getMonth() + 1);
    if (nextBilling.getDate() !== anchorDay) {
      nextBilling.setDate(0);
    }
  }
  return nextBilling;
};
export const getEndingPeriod = (subscriptions: any[], user: any): Date|undefined => {
  const subscriptionEndPeriod = subscriptions?.[subscriptions.length-1]?.data?.current_period_end?.toDate()
  if (subscriptionEndPeriod) {
    return subscriptionEndPeriod;
  }
  const userCreationTime = user?.metadata?.creationTime;
  if (userCreationTime) {
    return nextBillingDate(new Date(userCreationTime));
  }
};

export const getBillingPageUrl = async (returnUrl: string) => {
  const functionRef = httpsCallable(stripeFunctions, 'ext-firestore-stripe-payments-createPortalLink');
  const { data } = await functionRef({
    returnUrl,
    locale: "auto", // Optional, defaults to "auto"
  });
  // @ts-ignore
  return data.url;
}