import React, {useLayoutEffect, useState} from 'react';
import '../index.scss';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../utils/firebase";
import {signOutUser, loginExtension} from "../utils/authentication";
import {Link, navigate} from "gatsby"
import {useCurrentUsage, useUser} from "../utils/user";
import {isDefaultPlan, isFreePlan, useProducts} from "../utils/prices";
import {Dot, Loader, Logo} from "../components/Logo";
import {prettyDate} from "../utils";
import {getBillingPageUrl, getCurrentProduct, getEndingPeriod, useCurrentSubscriptions} from "../utils/subscription";
import {Page} from "../components/Page";
import {AddToBrowser} from "../components/AddToBrowser";
import {track} from "../utils/analytics";


const AccountPage = () => {
  const user = useUser();
  const subscriptions = useCurrentSubscriptions(user?.uid);
  const usage = useCurrentUsage(user?.uid);
  const products = useProducts();
  const [forceLoading, setForceLoading] = useState<boolean>(false);
  const isLoading = forceLoading || !user || !subscriptions || !products;
  const currentProduct = getCurrentProduct(subscriptions, products);

  useLayoutEffect(() => {
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        // we log in the extension to keep it refreshed
        await loginExtension();
      } else {
        track('redirected_to_signin', { from: 'account' });
        navigate('/signin');
      }
    });
  }, []);


  const totalUsage =
    (usage?.ask_count || 0) +
    (usage?.explain_count || 0) +
    (usage?.summarize_count || 0);
  const currentPeriodEnd = getEndingPeriod(subscriptions, user);
  const trialEnd = subscriptions?.[0]?.data?.trial_end?.toDate();
  const hasTrial = trialEnd && trialEnd > Date.now();
  const isFree = isFreePlan(currentProduct);
  const isDefault = isDefaultPlan(currentProduct);


  const goToSubscriptionPage = async () => {
    setForceLoading(true);
    try {
      const url = await getBillingPageUrl(window.location.origin + '/account');
      window.location.assign(url);
    } catch (error) {
      track('error_going_to_subscription_page', { error });
      console.error(error);
      setForceLoading(false);
    }
  }

  const onSignOut = async () => {
    track('clicked_sign_out');
    await signOutUser();
  }


  return <Page title='Account'>
    {isLoading && <Loader/>}
    <section>
      <Link to='/' className='no-style'><Logo/></Link>
      <div className='spacer'/>
      <h1>Account<Dot/></h1>

      <h3>
        <span className='label'>Email:</span>
        {user && <span className='unbold'>{user?.email}</span>}
      </h3>

      <h3>
        <span className='label'>Plan:</span>
        {currentProduct &&
        <span className='unbold'>{currentProduct?.data?.name}</span>}
        {hasTrial && <i className='subtitle'> (Free trial ends {prettyDate(trialEnd)})</i>}
      </h3>

      <h3>
        <span className='label'>Usage:</span>
        {currentPeriodEnd &&
        <span className='unbold'>{totalUsage} / {currentProduct?.data?.stripe_metadata_prompt_limit} prompts
            <i className='subtitle'> (Count resets {prettyDate(currentPeriodEnd)})</i></span>}
      </h3>

      <button onClick={() => {
        if (isDefault) {
          track('clicked_choose_upgrade');
          navigate('/plans');
        }
        else {
          track('clicked_manage_subscription', { source: 'account_page' });
          goToSubscriptionPage();
        }
      }}>
        {isFree ? 'Upgrade' : 'Manage Subscription'}
      </button>
      <button onClick={onSignOut} style={{marginTop: 20}}>
        Sign Out
      </button>


      <AddToBrowser style={{width: 'fit-content', marginTop: 250}}/>
    </section>
  </Page>;
};

export default AccountPage;
